<template>
  <div>
      Module not available yet.
    <teleport to="body">
      <!-- <personal-information-modal
        v-if="!isLoading"
        :animateButton="animateButton"
        :class="{ hidden: hideModal }"
        :profileData="getProfile"
        @submit-modal="submitModal"
        @close-modal="toggleModal"
      ></personal-information-modal> -->
    </teleport>
    <!-- <profile-header
      v-if="!isLoading"
      :profileData="getProfile"
      :selectedComponent="selectedComponent"
      @selectedTab="selectedTab"
      @personalInfoModal="toggleModal"
    /> -->

    <component v-if="!isLoading" :is="selectedComponent"></component>
  </div>
</template>

<script>
import personalInformationModal from "@/components/modal/profile/MA/personalInformationModal.vue";
import profileHeader from "@/components/layout/profile/profileHeader.vue";
import profile from "@/components/layout/profile/tabs/profile.vue";
import employmentHr from "@/components/layout/profile/tabs/employmentHR.vue";
import sicknessLeave from "@/components/layout/profile/tabs/sicknessLeave.vue";
import payrollBanking from "@/components/layout/profile/tabs/payrollBanking.vue";
import training from "@/components/layout/profile/tabs/training.vue";
import appraisals from "@/components/layout/profile/tabs/appraisals.vue";
import correspondence from "@/components/layout/profile/tabs/correspondence.vue";
import notes from "@/components/layout/profile/tabs/notes.vue";
import documents from "@/components/layout/profile/tabs/documents.vue";
export default {
  components: {
    profileHeader,
    profile,
    employmentHr,
    sicknessLeave,
    payrollBanking,
    training,
    appraisals,
    correspondence,
    notes,
    documents,
    personalInformationModal,
  },
  created() {
    // this.fetchProfile();
  },
  beforeRouteLeave() {
    // Set Rota information to null
    this.$store.commit("profile/setWorkTimings", null);
  },
  data() {
    return {
      isLoading: false,
      hideModal: true,
      animateButton: false,
      selectedComponent: this.$route.query.activeTab,
      componentProps: { profileData: this.getProfile },
    };
  },
  methods: {
    async fetchProfile() {
      try {
        this.isLoading = true;
        await this.$store.dispatch("profile/loadProfile");
        this.isLoading = false;
      } catch (error) {
        this.error = error.message || "Something went wrong!";
      }
    },
    selectedTab(tab) {
      this.$router.push({ query: { activeTab: tab } });
      this.selectedComponent = tab;
    },
    toggleModal() {
      this.hideModal = !this.hideModal;
    },
    async submitModal(actionName, actionPayload) {
      this.animateButton = true;
      try {
        await this.submitAction(actionName, actionPayload);
        this.hideModal = !this.hideModal;
        this.animateButton = false;
      } catch (error) {
        this.animateButton = false;
      }
    },
    // Trigger Action
    async submitAction(actionName, actionPayload) {
      try {
        await this.$store.dispatch(actionName, actionPayload);
        this.$toast.success(`Updated Successful`);
      } catch (error) {
        this.$toast.error("Oops something went wrong");
      }
    },
  },
  computed: {
    getProfile() {
      return this.$store.getters["profile/getProfile"];
    },
  },
  watch: {
    $route(newRoute) {
      this.selectedComponent = newRoute.query.activeTab;
      this.selectedTab(newRoute.query.activeTab);
    },
  },
};
</script>
